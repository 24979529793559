<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                  <template v-slot:projectNameSlot>
                    {{ }}
                  </template>
                  <b-row v-if="detailsData">
                    <b-col>
                        <h6 class="text-center" style="font-weight:bold">{{ getFairName(detailsData.app_main.fair_id) }}</h6>
                        <!-- <div class="text-center">{{ $i18n.locale == 'en' ? fair_info.fiscal_year_en : fair_info.fiscal_year_bn }}</div> -->
                    </b-col>
                  </b-row>
                </list-report-head>
                <b-row>
                    <b-col md="6" class="mb-2 text-center mx-auto">
                        <h5  style="font-weight:bold;background-color:#ddd">{{ $t('globalTrans.payment') + ' ' + $t('globalTrans.receipt') }}</h5>
                    </b-col>
                </b-row>
                <b-row v-if="detailsData">
                    <b-table-simple v-if="detailsData.app_main.circular_type == 1" bordered small>
                        <thead>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th v-if="detailsData.app_main.circular_type == 1" style="width:10%">{{ $t('ditfConfig.stall_no') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.processing_fee') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <!-- <th style="width:15%">{{ $t('externalTradeFair.highest_quoted') }}</th> -->
                                <!-- <th style="width:15%">{{ $t('externalTradeFair.due') }}</th> -->
                            </tr>
                        </thead>
                        <b-tbody>
                        <b-tr class="text-center">
                            <b-td class="align-middle text-center">{{ $i18n.locale == 'en' ? category_info?.text_en : category_info?.text_bn }}</b-td>
                            <b-td v-if="detailsData.app_main.circular_type == 1" class="text-center">{{ $t('externalTradeFair.stall') + '-' + detailsData.stall.stall_no }}</b-td>
                            <b-td class="align-middle text-center">{{ $n(category_info?.processing_fee, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td class="align-middle text-center">{{ $n(category_info?.floor_price, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td class="text-right">{{ $n(detailsData.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                            <!-- <b-td class="align-middle text-center">{{ $n(detailsData.security_money, { minimumFractionDigits: 2 }) + '(' + $n(getSecurityPrc(detailsData.app_main.year)) + '%)'}}</b-td> -->
                            <!-- <b-td class="align-middle text-right">{{ $n(detailsData.quoted_price - detailsData.security_money, { minimumFractionDigits: 2 }) }}</b-td> -->
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="4">{{ $t('externalTradeFair.vat') + '(' + $n(getVatPrc(detailsData.app_main.year)) + '%)' }}</b-td>
                            <b-td class="text-right">{{ $n(getVatAmount(detailsData.quoted_price, { minimumFractionDigits: 2 })) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="4">{{ $t('externalTradeFair.tax') + '(' + $n(getTaxPrc(detailsData.app_main.year)) + '%)' }}</b-td>
                            <b-td class="text-right">{{ $n(getTaxAmount(detailsData.quoted_price, { minimumFractionDigits: 2 })) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="4">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                            <b-td class="bg-warning text-right">{{ $n(payment.payment_amount, { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple v-else bordered small>
                        <thead>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.processing_fee') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <!-- <th style="width:15%">{{ $t('externalTradeFair.due') }}</th> -->
                            </tr>
                        </thead>
                        <b-tbody>
                        <b-tr class="text-center">
                            <b-td class="align-middle text-center">{{ $i18n.locale == 'en' ? category_info?.text_en : category_info?.text_bn }}</b-td>
                            <b-td class="align-middle text-center">{{ $n(category_info?.processing_fee, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td class="align-middle text-center">{{ $n(category_info?.floor_price, { minimumFractionDigits: 2 }) }}</b-td>
                            <!-- <b-td class="text-center">{{ $n(detailsData.quoted_price) }}</b-td> -->
                            <b-td class="align-middle text-right">{{ $n(detailsData.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="3">{{ $t('externalTradeFair.vat') + '(' + $n(getVatPrc(detailsData.app_main.year)) + '%)' }}</b-td>
                            <b-td class="text-right">{{ $n(getVatAmount(detailsData.quoted_price, { minimumFractionDigits: 2 })) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="3">{{ $t('externalTradeFair.tax') + '(' + $n(getTaxPrc(detailsData.app_main.year)) + '%)' }}</b-td>
                            <b-td class="text-right">{{ $n(getTaxAmount(detailsData.quoted_price, { minimumFractionDigits: 2 })) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="3">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                            <b-td class="bg-warning text-right">{{ $n(payment.payment_amount, { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-row>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                        <div class="d-flex justify-content-center">
                            <b-card class="payment-wrapper">
                                <p>{{ $t('teaGardenBtriService.payment_option') }}</p>
                                <div class="pay-options">
                                    <input disabled type="radio" v-model="payment.payment_type" :value="2" name="select" v-if="paymentTypeList[0].text_en === 'Bank' || paymentTypeList[1].text_en === 'Bank'" id="bank" :checked="paymentTypeList[0].text_en === 'Bank' ? true : false">

                                    <input type="radio" v-model="payment.payment_type" :value="3" name="select" id="Online" v-if="paymentTypeList[1].text_en === 'Online'">

                                    <label for="bank" class="option bank" v-if="paymentTypeList[0].text_en === 'Bank' || paymentTypeList[1].text_en === 'Bank'">
                                        <div class="dot"></div>
                                        <span v-if="paymentTypeList[0].text_en === 'Bank'">{{ currentLocale === 'en' ? paymentTypeList[0].text_en : paymentTypeList[0].text_bn }}</span>
                                        <span v-if="paymentTypeList[1].text_en === 'Bank'">{{ currentLocale === 'en' ? paymentTypeList[1].text_en : paymentTypeList[1].text_bn }}</span>
                                    </label>

                                    <label for="Online" class="option Online" v-if="paymentTypeList[1].text_en === 'Online'">
                                        <div class="dot"></div>
                                        <span>{{ currentLocale === 'en' ? paymentTypeList[1].text_en : paymentTypeList[1].text_bn }}</span>
                                    </label>
                                </div>
                                <div class="payment-info-boxs">
                                    <b-row v-if="payment.payment_type === 2">
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalTradeFair.bank_deposit', 'en')" vid="voucher_no" rules="required" v-slot="{ errors }">
                                                <b-form-group label-for="voucher_no" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalTradeFair.bank_deposit')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="payment.voucher_no"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('globalTrans.date', 'en')" vid="pay_date" rules="required" v-slot="{ errors }">
                                                <b-form-group class="row mb-2"
                                                label-cols-sm="4"
                                                label-for="pay_date"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.date') }} <span class="text-danger">*</span>
                                                </template>
                                                <date-picker
                                                    id="pay_date"
                                                    v-model="payment.pay_date"
                                                    class="form-control"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :class="errors[0] ? 'is-invalid' : ''"
                                                >
                                                </date-picker>
                                                <div class="invalid-feedback d-block">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalUser.bank_name', 'en')" vid="bank_id" rules="required|min_value:1" v-slot="{ errors }">
                                                <b-form-group label-for="bank_id" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalUser.bank_name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="payment.bank_id"
                                                        :options="sortedBankList"
                                                        @change="getBranchList(payment.bank_id)"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalUser.branch_name', 'en')" vid="branch_name" rules="required" v-slot="{ errors }">
                                                <b-form-group label-for="branch_name" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalUser.branch_name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="payment.branch_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalUser.branch_name', 'en')" vid="branch_id" rules="required|min_value:1" v-slot="{ errors }">
                                                <b-form-group label-for="branch_id" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalUser.branch_name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="payment.branch_id"
                                                        :options="branchList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col> -->
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('globalTrans.voucher', 'en')" vid="attachment" rules="required" v-slot="{ errors }">
                                                <b-form-group class="row mb-2"
                                                    label-cols-sm="4"
                                                    label-for="attachment">
                                                    <template v-slot:label>
                                                        {{ $t('externalTradeFair.check_deposit') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-file
                                                        plain
                                                        v-on:change="onFileChange($event)"
                                                        v-model="attachment_input"
                                                        accept=".jpg,.jpeg,.pdf"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    <div class="invalid-feedback d-block text-muted">
                                                        {{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট JPG/JPEG/PDF]' }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" class="offset-md-4">
                                        <wizard-button @click="finalSave" class="btn btn-success ml-1 mr-1" title="Submit">
                                            <i class="ri-secure-payment-line"></i>
                                            <span v-if="payment.payment_type === 2">
                                            {{$t('globalTrans.submit')}}</span>
                                            <span v-if="payment.payment_type === 3"> {{$t('teaGardenBtriService.online_payment')}}</span>
                                        </wizard-button>
                                        <!-- cancel button  -->
                                        <router-link :to="{name: $route.query.from}" v-if="$route.query.from" class="btn btn-danger ml-1 mr-1 text-white"><i
                                            class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}
                                        </router-link>
                                        </b-col>
                                    </b-row>
                                </div>
                        </b-card>
                        </div>
                    </b-form>
                </ValidationObserver>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { DitfAppStallPaymentStore, DitfOnlineAppStallPaymentStore } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
  name: 'Form',
  props: ['id'],
  components: {
    ListReportHead
  },
  data () {
    return {
        valid: null,
        baseUrl: internationalTradeFairServiceBaseUrl,
        comp: 'PaymentOnline',
        loader: false,
        detailsData: {},
        is_payment: false,
        branchList: [],
        category_info: {},
        payment: {
            payment_type: 3,
            app_main_id: 0,
            stall_cat_id: 0,
            stall_id: 0,
            security_money: 0,
            payment_amount: 0,
            voucher_no: '',
            bank_id: 0,
            branch_id: 0,
            attachment: '',
            pay_date: ''
        },
        sortedBankList: [],
        attachment_input: []
    }
  },
  created () {
    if (this.id) {
        this.detailsData = this.getPayInfo()
        this.detailsData.vat = this.getVatAmount(this.detailsData.quoted_price)
        this.detailsData.tax = this.getTaxAmount(this.detailsData.quoted_price)
        this.payment.app_main_id = this.detailsData.app_main_id
        this.payment.stall_cat_id = this.detailsData.stall_cat_id
        this.payment.tax = this.detailsData.vat
        this.payment.vat = this.detailsData.tax
        if (this.detailsData.app_main.circular_type === 1) {
            this.payment.stall_id = this.detailsData.stall_id
            this.payment.security_money = this.detailsData.security_money
            // const dueAmount = (this.detailsData.quoted_price - this.detailsData.security_money)
            // this.payment.due_amount = dueAmount
            // this.payment.payment_amount = (parseFloat(dueAmount) + this.detailsData.vat + this.detailsData.tax)
            this.payment.payment_amount = (parseFloat(this.detailsData.quoted_price) + this.detailsData.vat + this.detailsData.tax)
        } else {
            // const dueAmount = (this.detailsData.quoted_price)
            // this.payment.payment_amount = (parseInt(dueAmount) + this.detailsData.vat + this.detailsData.tax)
            this.payment.payment_amount = parseFloat(this.detailsData.vat) + parseFloat(this.detailsData.tax)
        }
        // category info
        this.category_info = this.getStallCategoryName(this.detailsData.stall_cat_id)
        this.getFairBankId()
    }
  },
  watch: {
  },
  computed: {
    bankList () {
            return this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
    },
    paymentTypeList: function () {
        return this.$store.state.TeaGardenService.commonObj.btriPaymentType.filter(item => item.value !== 1)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getSecurityPrc (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(n))
        if (typeof Obj !== 'undefined') {
          const percentage = Obj.percentage
          return percentage
        } else {
        return 0
        }
    },
    getVatPrc (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(n))
        if (typeof Obj !== 'undefined') {
          const vat = Obj.vat
          return vat
        } else {
        return 0
        }
    },
    getVatAmount (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(this.detailsData.app_main.year))
        if (typeof Obj !== 'undefined') {
          const vat = Obj.vat
          const vatAmount = (n * vat) / 100
          const vatMoney = vatAmount
          return vatMoney
        } else {
        return 0
        }
    },
    getTaxPrc (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(n))
        if (typeof Obj !== 'undefined') {
          const tax = Obj.tax
          return tax
        } else {
        return 0
        }
    },
    getTaxAmount (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(this.detailsData.app_main.year))
        if (typeof Obj !== 'undefined') {
          const tax = Obj.tax
          const taxAmount = (n * tax) / 100
          const taxMoney = taxAmount
          return taxMoney
        } else {
        return 0
        }
    },
    getBranchList (Id) {
        this.branchList = this.$store.state.CommonService.commonObj.branchList.filter(item => item.bank_id === parseInt(Id))
    },
    getFairName (id) {
        const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.dhakaFairList.find(item => parseInt(item.value) === parseInt(id))
        if (this.currentLocale === 'bn') {
          return Obj !== undefined ? Obj.text_bn : ''
        } else {
          return Obj !== undefined ? Obj.text_en : ''
        }
    },
    getStallCategoryName (id) {
        return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
    },
    getPayInfo () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onFileChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            this.payment.attachment = event.target.files[0]
        }
    },
    finalSave () {
        this.$swal({
            title: this.$t('globalTrans.surePrompt'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                if (this.payment.payment_type === 3) {
                    this.onlinePayment()
                } else {
                    this.saveUpdate()
                }
            }
        })
    },
    async onlinePayment () {
        let tmpPay = {}
        const name = this.$store.state.Auth.authUser.name
        const email = this.$store.state.Auth.authUser.email
        const mobile = this.$store.state.Auth.authUser.mobile
        if (this.circular_type === 1) {
            this.payment.details = JSON.stringify(this.details)
        }
        tmpPay = Object.assign(this.payment, { applicant_name: name, email: email, mobile: mobile })
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        this.loader = true
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.postData(internationalTradeFairServiceBaseUrl, DitfOnlineAppStallPaymentStore, tmpPay)
        loadingState.listReload = true
        this.loader = false
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
                this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                window.location = result.url
                // this.$bvModal.hide('modal-payment')
        } else {
                this.$refs.form.setErrors(result.errors)
        }
    },
    async saveUpdate () {
        var check = await this.$refs.form.validate()
        if (check) {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            this.loader = true
            const loadingState = { loading: false, listReload: false }
            const formData = new FormData()
            Object.keys(this.payment).map(key => {
                if (key === 'attachment') {
                    formData.append(key, this.attachment_input)
                } else {
                    formData.append(key, this.payment[key])
                }
            })

            if (this.id) {
                result = await RestApi.postData(internationalTradeFairServiceBaseUrl, DitfAppStallPaymentStore, formData)
            }
            this.loader = false
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-payment')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    },
    async getFairBankId () {
      const bankList = this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, '/ditf-configuration/bank-info/details', null)
      if (result.success) {
        this.sortedBankList = bankList.filter(item => result.data.includes(item.value))
      } else {
        this.sortedBankList = []
      }
    }
  }
}
</script>

<style scoped>
.p_heading{
      font-weight: bold;
    }

.payment-wrapper {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 20px 15px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
}
.pay-options {
  display: inline-flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.pay-options .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0 10px;
  border: 1px solid lightgrey;
  transition: all 0.3s ease;
}

.pay-options .option .dot {
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.pay-options .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#cash:checked:checked~.cash,
#bank:checked:checked~.bank,
#Online:checked:checked~.Online {
  border-color: #0069d9;
  background: #0069d9;
}

#cash:checked:checked~.cash .dot,
#bank:checked:checked~.bank .dot,
#Online:checked:checked~.Online .dot {
  background: #fff;
}

#cash:checked:checked~.cash .dot::before,
#bank:checked:checked~.bank .dot::before,
#Online:checked:checked~.Online .dot::before {
  opacity: 1;
  transform: scale(1);
}

.pay-options .option span {
  font-size: 20px;
  color: #808080;
}

#cash:checked:checked~.cash span,
#bank:checked:checked~.bank span,
#Online:checked:checked~.Online span {
  color: #fff;
}
.payment-info-boxs {
  margin-top: 1.5rem;
}
.payment-info tr{
  border-bottom: 1px solid lightgrey;
}
.payment-info td{
  padding: .4rem;
}
.payment-info tr:last-child{
  border-bottom: none;
}
.payment-info tr td:last-child{
  font-weight: 700;
  padding-left: 1rem;
}
</style>
